// Custom hook that is used when pulling the historical data of a data object
import { useState } from "react";
import { useSelector } from "react-redux";

import { getHistoricalDataAPI } from "../axios";
import { agencyHeaders, vehicleHeaders, LocationHeaders } from "../assets";

export const useHistoricalData = ({
  collectionName,
}: {
  collectionName: string;
}) => {
  const [historicalData, setHistoricalData] = useState<any>(null);

  const { vehicleStatusData } = useSelector((state: any) => state.vehicles);
  const { agencyStatusesData } = useSelector((state: any) => state.agencies);
  const locationStatuses = useSelector(
    (state: any) => state.locationStatuses.locationStatusesData
  );

  const getDataHeaders = () => {
    let headers: {
      name: string;
      value: string;
      stringMethod?: (update: any) => any;
    }[] = [];
    // Handle Vehicles
    if (collectionName === "Vehicles") {
      // Insert Services
      const servicesHeader = {
        name: "Services",
        value: "subscriptions",
        stringMethod: (services: any) => {
          if (!services.length) {
            return "[]";
          }
          return `[${services.map((service: any) => service.service)}]`;
        },
      };
      // Insert Traces Reported Flag
      const tracesReportedFlagHeader = {
        name: "Traces Reported Flag",
        value: "tracesReportedFlag",
      };
      // Insert Communication Preferences
      const communicationPreferencesHeader = {
        name: "Communication Preferences",
        value: "communicationPreferences",
        stringMethod: (communicationPreferences: any) => {
          return communicationPreferences.receiveInfotainmentMessages;
        },
      };
      // Track status changed
      const statusHeader = {
        name: "Status",
        value: "status",
        stringMethod: (status: any) => {
          return vehicleStatusData.find(
            (vehicleStatus: any) => vehicleStatus._id === status
          )?.name;
        },
      };

      headers = [
        ...vehicleHeaders,
        servicesHeader,
        tracesReportedFlagHeader,
        communicationPreferencesHeader,
        statusHeader,
      ];
    }

    // Handle Agencies
    if (collectionName === "Agencies") {
      // Insert Services
      const servicesHeader = {
        name: "Services",
        value: "services",
        stringMethod: (services: any) => {
          if (!services.length) {
            return "[]";
          }
          return `[${services.map((service: any) => service)}]`;
        },
      };
      // Split the charge object
      const chargeHeader = {
        name: "Type",
        value: "charge",
        stringMethod: (charge: any) => {
          return `${charge.type}\nMax HOV: ${charge.maxHov}`;
        },
      };
      // Track status changed
      const statusHeader = {
        name: "Status",
        value: "status",
        stringMethod: (status: any) => {
          return agencyStatusesData.find(
            (agencyStatus: any) => agencyStatus._id === status
          )?.name;
        },
      };

      headers = [...agencyHeaders, servicesHeader, chargeHeader, statusHeader];
    }

    // Handle Locations
    if (collectionName === "Locations") {
      // Insert Coords Changed
      const coordsHeader = {
        name: "Coordinates Changed",
        value: "coordsChanged",
      };
      // Insert Schedules Changed
      const schedulesHeader = {
        name: "Schedules Changed",
        value: "schedulesChanged",
      };
      // Insert Status
      const statusHeader = {
        name: "Status",
        value: "status",
        stringMethod: (status: any) => {
          return locationStatuses.find(
            (locationStatus: any) => locationStatus._id === status
          )?.name;
        },
      };
      // Insert Course
      const courseHeader = {
        name: "Course",
        value: "course",
      };
      // Insert Entry Action
      const entryActionHeader = {
        name: "Entry Action",
        value: "entryActionChanges",
      };
      // Insert Exit Action
      const exitActionHeader = {
        name: "Exit Action",
        value: "exitActionChanges",
      };
      const lockStatusHeader = {
        name: "Lock Status",
        value: "lockStatus",
      };
      const locationSubTypeHeader = {
        name: "Location Subtype",
        value: "locationSubType",
      };
      // Insert First Trace
      const firstTraceHeader = {
        name: "First Trace",
        value: "firstTraceChanges",
      };

      headers = [
        ...LocationHeaders,
        coordsHeader,
        schedulesHeader,
        statusHeader,
        courseHeader,
        entryActionHeader,
        exitActionHeader,
        lockStatusHeader,
        locationSubTypeHeader,
        firstTraceHeader,
      ];
    }

    return headers;
  };

  // Helper function to convert the updates object to cell string with new lines
  // Using the table headers to get the name of the key for easier reading
  const updatesToCell = (
    updates: any,
    headers: {
      name: string;
      value: string;
      stringMethod?: (update: any) => any;
    }[]
  ) => {
    if (!updates) {
      return "";
    }

    let cellString = "";
    for (const key in updates) {
      const header = headers.find((header) => header.value === key);
      if (header) {
        // Some headers will have a string method that we can use to get a string of the object for easier reading
        if (header?.stringMethod) {
          const stringifiedObj = header.stringMethod(updates[header.value]);
          cellString += `${header.name}: ${stringifiedObj}\n`;
        } else {
          cellString += `${header.name}: ${updates[header.value]}\n`;
        }
      }
    }

    return cellString;
  };

  // Handle getting the historical data of a data object
  const fetchHistoricalData = async ({
    parentObjectId,
  }: {
    parentObjectId: string;
  }) => {
    if (!parentObjectId) {
      return;
    }

    let res = await getHistoricalDataAPI({
      collectionName,
      parentObjectId,
    });
    const headers = getDataHeaders();
    res = res?.map((item: any) => {
      return {
        ...item,
        updatesString: updatesToCell(item.updates, headers),
      };
    });
    setHistoricalData(res);
  };

  return {
    historicalData,
    fetchHistoricalData,
  };
};
