import { RatesManagement } from "./RatesManagement";
import { ConnectOneButton } from "../../components";

import "./RatesManagement.scss";

export const DistanceRatesManagement = ({
  geofence,
  entryPoint,
  entryPointSchedules,
  handleInsertScheduleOrRate,
  handleScheduleOrRateChange,
  handleRemoveScheduleOrRate,
  handleScheduleSave,
  handleUndoScheduleChanges,
}: {
  geofence: any;
  entryPoint: any;
  entryPointSchedules: any[];
  handleInsertScheduleOrRate: any;
  handleScheduleOrRateChange: any;
  handleRemoveScheduleOrRate: any;
  handleScheduleSave: any;
  handleUndoScheduleChanges: any;
}) => {
  const getOrdinal = (entryPointOrder: any) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = entryPointOrder % 100;
    const suffix = suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0];
    return (
      <>
        {entryPointOrder}
        <sup>{suffix}</sup>
      </>
    );
  };
  return (
    <div className="distance-rate-entry-container">
      {entryPoint && (
        <>
          <h1>
            {getOrdinal(entryPoint.entryPointOrder)} Entry point:{" "}
            {entryPoint.name} ({entryPoint.plazaId})
          </h1>
          {entryPointSchedules?.length > 0 && (
            <>
              {entryPointSchedules.map((schedule: any, index: number) => {
                return (
                  <div key={`schedule-edit-form-${index}`}>
                    <RatesManagement
                      schedule={schedule}
                      index={schedule.scheduleIndex}
                      handleInsertScheduleOrRate={handleInsertScheduleOrRate}
                      handleScheduleOrRateChange={handleScheduleOrRateChange}
                      handleRemoveScheduleOrRate={handleRemoveScheduleOrRate}
                      handleScheduleSave={handleScheduleSave}
                      handleUndoScheduleChanges={handleUndoScheduleChanges}
                    />
                  </div>
                );
              })}
            </>
          )}
          <div className="distance-button-container">
            <ConnectOneButton
              label={`Add New Rate`}
              handleClick={() =>
                handleInsertScheduleOrRate({
                  objectType: "schedule",
                  entryPointId: entryPoint.id,
                })
              }
            />
          </div>
        </>
      )}
    </div>
  );
};
