import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { Rate } from "../../../types";
import { daysOfWeek } from "../../../assets";
import { useIsMobile, useReport } from "../../../hooks";
import { reportDescription } from "./RatesReportDescription";
import { getRateAPI, getRateAdvancedAPI } from "../../../axios";
import {
  ConnectOneTable,
  ConnectOneTableControls,
  ConnectOneModal,
  ConnectOneAutocomplete,
  ConnectOneButton,
  ConnectOneInput,
  ConnectOneDropdown,
  ConnectOneCheckbox,
} from "../../../components";

import "./Rates.scss";

export const RateReport = () => {
  const isMobile = useIsMobile();
  const [isViewingAdvancedSearch, setIsViewingAdvancedSearch] = useState(false);

  const agencies = useSelector((state: any) => state.agencies.agenciesData);
  const classTypes = useSelector(
    (state: any) => state.classTypes.classTypesData
  );
  const locationStatuses = useSelector(
    (state: any) => state.locationStatuses.locationStatusesData
  );
  const locationHierarchy = useSelector(
    (state: any) => state.locationHierarchy.locationHierarchyData
  );

  const classTypeMap = new Map(
    classTypes.map((classType: { _id: string; name: string }) => [
      classType._id,
      classType.name,
    ])
  );

  const {
    reportData,
    currentPageIndex,
    totalPages,
    dataToDisplay,
    isLoading,
    headers,
    exportData,
    sortAscending,
    sortedValue,
    exportFileName,
    setIsLoading,
    setReportData,
    setCurrentPageIndex,
    handleSorting,
  } = useReport({ isMobile, reportType: "rates", dataPerPage: 120 });

  const [filterValues, setFilterValues] = useState({
    agencyId: {
      type: "autocomplete",
      label: "agency",
      value: "",
      options: [] as any,
      placeholderText: "Select an Agency",
    },
    roadName: {
      label: "Road Name",
      value: "",
      type: "text",
    },
  });

  const emptyAdvancedFilterValues = {
    status: {
      label: "Status",
      value: "",
    },
    type: {
      label: "Type",
      value: "",
    },
    subType: {
      label: "Subtype",
      value: "",
    },
    plateRead: {
      label: "Plate Rate",
      value: {
        minAmount: "",
        maxAmount: "",
        isNull: false,
      },
    },
    cashRate: {
      label: "Cash Rate",
      value: {
        minAmount: "",
        maxAmount: "",
        isNull: false,
      },
    },
    avi: {
      label: "AVI",
      value: {
        minAmount: "",
        maxAmount: "",
        isNull: false,
      },
    },
    equityRate: {
      label: "Equity Rate",
      value: {
        minAmount: "",
        maxAmount: "",
        isNull: false,
      },
    },
    hovRate: {
      label: "HOV Rate",
      value: {
        minAmount: "",
        maxAmount: "",
        isNull: false,
      },
    },
    negotiatedRate: {
      label: "Negotiated Rate",
      value: {
        minAmount: "",
        maxAmount: "",
        isNull: false,
      },
    },
  };
  const [advancedFilterValues, setAdvancedFilterValues] = useState<{
    [key: string]: any;
  }>({ ...emptyAdvancedFilterValues });

  useEffect(() => {
    setFilterValues((prevFilterValues: any) => ({
      ...prevFilterValues,
      agencyId: {
        ...prevFilterValues.agencyId,
        options: [
          { label: "Select an Agency", value: "", disabled: true },
          ...agencies,
        ],
      },
    }));
  }, [agencies]);

  const submitButtonEnabled = Object.values(filterValues).some(
    (filterValue: any) => !!filterValue.value
  );

  const chargeTypes = [
    { label: "Plate Rate", value: "plateRead" },
    { label: "Cash Rate", value: "cashRate" },
    { label: "AVI Rate", value: "avi" },
    { label: "Equity Rate", value: "equityRate" },
    { label: "HOV Rate", value: "hovRate" },
    { label: "Negotiated Rate", value: "negotiatedRate" },
  ];

  const locationTypes = useMemo(() => {
    if (locationHierarchy?.get) {
      const locationTypes: { label: string; value: string }[] = [];

      locationHierarchy.forEach((locationType: any) => {
        locationTypes.push({
          label: locationType.locationType,
          value: locationType.locationType,
        });
      });

      return locationTypes;
    }
  }, [locationHierarchy]);

  const locationSubTypes = useMemo(() => {
    if (advancedFilterValues.type.value && locationHierarchy?.get) {
      const { subTypeEventActions } = locationHierarchy?.get(
        `${advancedFilterValues.type.value}`
      );

      const locationSubTypes: { label: string; value: string }[] = [];

      if (subTypeEventActions) {
        subTypeEventActions.forEach((subType: any) => {
          if (subType.locationSubType) {
            locationSubTypes.push({
              label: subType.locationSubTypeName,
              value: subType.locationSubType,
            });
          }
        });
      }

      return locationSubTypes.filter(
        (subType) => subType.value !== "DISTANCEENTRY"
      );
    }
  }, [advancedFilterValues.type.value, locationHierarchy]);

  // Shape the nested data to display in table
  // For each schedule and charge we should have a row to display
  const shapeRatesData = (data: any) => {
    return data.reduce((acc: any[], rate: any) => {
      const { name, schedules } = rate;
      const { agencyId, roadName } = rate?.properties;
      const { name: agencyName, state } = agencies?.find(
        (agency: any) => agency._id === agencyId
      );

      // If distance based we should change location name to be Entry / Exit
      return acc.concat(
        schedules.reduce((innerAcc: any[], schedule: any) => {
          let startOfSegmentLocationName: any = null;
          let exclusiveDates: any = null;
          if (schedule?.startOfSegmentLocationId) {
            startOfSegmentLocationName = data.find(
              (location: any) =>
                location._id === schedule?.startOfSegmentLocationId
            )?.name;
          }
          if (schedule?.exclusiveDates) {
            exclusiveDates = schedule.exclusiveDates.map((date: any) =>
              new Date(date).toLocaleDateString()
            );
          }
          const { charges } = schedule;
          if (exclusiveDates && exclusiveDates.length > 0) {
            return innerAcc.concat(
              charges.map((charge: any) => ({
                ...schedule,
                ...charge,
                name: startOfSegmentLocationName
                  ? `Entry: ${startOfSegmentLocationName}\nExit: ${name} `
                  : name,
                agencyName,
                state,
                roadName: roadName ? roadName : "",
                startOfSegmentLocationName,
                vehicleClass: classTypeMap.get(schedule.vehicleClass),
                exclusiveDates: exclusiveDates.join(", "),
              }))
            );
          } else if (charges && charges.length > 0) {
            return innerAcc.concat(
              charges.map((charge: any) => ({
                ...schedule,
                ...charge,
                name: startOfSegmentLocationName
                  ? `Entry: ${startOfSegmentLocationName}\nExit: ${name} `
                  : name,
                agencyName,
                state,
                roadName: roadName ? roadName : "",
                startOfSegmentLocationName,
                dayOfWeekStart: daysOfWeek[schedule?.dayOfWeekStart - 1],
                dayOfWeekEnd: daysOfWeek[schedule?.dayOfWeekEnd - 1],
                vehicleClass: classTypeMap.get(schedule.vehicleClass),
              }))
            );
          } else {
            return innerAcc.concat({
              ...schedule,
              name: startOfSegmentLocationName
                ? `Entry ${startOfSegmentLocationName}\nExit ${name} `
                : name,
              agencyName,
              state,
              roadName: roadName ? roadName : "",
              startOfSegmentLocationName,
              dayOfWeekStart: daysOfWeek[schedule?.dayOfWeekStart - 1],
              dayOfWeekEnd: daysOfWeek[schedule?.dayOfWeekEnd - 1],
              vehicleClass: classTypeMap.get(schedule.vehicleClass),
            });
          }
        }, [])
      );
    }, []);
  };

  const getTableData = async () => {
    setIsLoading(true);
    setCurrentPageIndex(1);
    try {
      const res: any = await getRateAPI({
        agencyId: filterValues.agencyId.value,
        roadName: filterValues.roadName.value,
      });
      setReportData(shapeRatesData(res));
    } catch (err) {
      setReportData([] as Rate[]);
    }
    setIsLoading(false);
  };

  const handleAutoCompleteChange = (e: any) => {
    const { name, value } = e;
    setFilterValues((prevFilterValues: any) => ({
      ...prevFilterValues,
      [name]: {
        ...prevFilterValues[name],
        value: value,
      },
    }));
  };

  const handleAdvancedSearchChange = (e: any) => {
    const { name, value, checked } = e.target;

    if (name === "roadName") {
      setFilterValues((prevFilterValues: any) => ({
        ...prevFilterValues,
        [name]: {
          ...prevFilterValues[name],
          value: value,
        },
      }));
      return;
    }

    // Handle setting nested values
    const [parentName, childName] = name.split(".");
    if (childName) {
      setAdvancedFilterValues((prevAdvancedFilterValues: any) => ({
        ...prevAdvancedFilterValues,
        [parentName]: {
          ...prevAdvancedFilterValues[parentName],
          value: {
            ...prevAdvancedFilterValues[parentName].value,
            [childName]: childName === "isNull" ? checked : value,
          },
        },
      }));
    } else {
      // If user changes the type need to reset the subtype
      if (name === "type") {
        setAdvancedFilterValues((prevAdvancedFilterValues: any) => ({
          ...prevAdvancedFilterValues,
          subType: {
            ...prevAdvancedFilterValues.subType,
            value: "",
          },
        }));
      }

      setAdvancedFilterValues((prevAdvancedFilterValues: any) => ({
        ...prevAdvancedFilterValues,
        [name]: {
          ...prevAdvancedFilterValues[name],
          value: value,
        },
      }));
    }
  };

  const getAdvancedSearchTableData = async () => {
    try {
      setIsLoading(true);
      setCurrentPageIndex(1);
      const res: any = await getRateAdvancedAPI({
        agencyId: filterValues.agencyId.value,
        roadName: filterValues.roadName.value,
        locationStatus: advancedFilterValues.status.value,
        locationType: advancedFilterValues.type.value,
        locationSubType: advancedFilterValues.subType.value,
        plateRead: {
          minAmount: advancedFilterValues.plateRead.value.minAmount,
          maxAmount: advancedFilterValues.plateRead.value.maxAmount,
          isNull: advancedFilterValues.plateRead.value.isNull,
        },
        cashRate: {
          minAmount: advancedFilterValues.cashRate.value.minAmount,
          maxAmount: advancedFilterValues.cashRate.value.maxAmount,
          isNull: advancedFilterValues.cashRate.value.isNull,
        },
        avi: {
          minAmount: advancedFilterValues.avi.value.minAmount,
          maxAmount: advancedFilterValues.avi.value.maxAmount,
          isNull: advancedFilterValues.avi.value.isNull,
        },
        equityRate: {
          minAmount: advancedFilterValues.equityRate.value.minAmount,
          maxAmount: advancedFilterValues.equityRate.value.maxAmount,
          isNull: advancedFilterValues.equityRate.value.isNull,
        },
        hovRate: {
          minAmount: advancedFilterValues.hovRate.value.minAmount,
          maxAmount: advancedFilterValues.hovRate.value.maxAmount,
          isNull: advancedFilterValues.hovRate.value.isNull,
        },
        negotiatedRate: {
          minAmount: advancedFilterValues.negotiatedRate.value.minAmount,
          maxAmount: advancedFilterValues.negotiatedRate.value.maxAmount,
          isNull: advancedFilterValues.negotiatedRate.value.isNull,
        },
      });

      setReportData(shapeRatesData(res));
    } catch (err) {
      setReportData([] as Rate[]);
    }
    setIsViewingAdvancedSearch(false);
    // Clear filter values and advanced search values
    setFilterValues({
      agencyId: {
        type: "autocomplete",
        label: "agency",
        value: "",
        options: [] as any,
        placeholderText: "Select an Agency",
      },
      roadName: {
        label: "Road Name",
        value: "",
        type: "text",
      },
    });
    setAdvancedFilterValues({ ...emptyAdvancedFilterValues });
    setIsLoading(false);
  };

  return (
    <div className="rates-report-container">
      <ConnectOneTableControls
        fullData={reportData}
        dataIsLoading={isLoading}
        totalPages={totalPages}
        currentPageIndex={currentPageIndex}
        setCurrentPageIndex={setCurrentPageIndex}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        handleFilterSubmit={getTableData}
        handleAdvancedSearch={() =>
          setIsViewingAdvancedSearch(!isViewingAdvancedSearch)
        }
        submitButtonDisabled={!submitButtonEnabled}
        fileName={exportFileName("RATES_REPORT", filterValues)}
        exportData={exportData}
        reportTitle="Agency Rates Report"
        reportDescription={reportDescription()}
      />

      {isViewingAdvancedSearch && (
        <ConnectOneModal
          title={"Advanced Search"}
          handleClose={() => setIsViewingAdvancedSearch(false)}>
          <div className="advanced-search-container">
            <h2>Agency Data</h2>
            <div className="inputs-container">
              <ConnectOneAutocomplete
                label={"Agency"}
                placeholderText={"Select an Agency"}
                handleChange={handleAutoCompleteChange}
                filterValues={filterValues}
                value={filterValues.agencyId.value}
                customTitle="Agency"
                required={true}
              />
              <ConnectOneInput
                handleChange={handleAdvancedSearchChange}
                label={"Road Name"}
                name={"roadName"}
                placeholder={"Road Name"}
                value={filterValues.roadName.value}
                type="text"
              />
            </div>

            <h2>Plaza Data</h2>
            <div className="inputs-container">
              <ConnectOneDropdown
                handleChange={handleAdvancedSearchChange}
                label={"Status"}
                name={"status"}
                placeholder={"Status"}
                value={advancedFilterValues.status.value}
                options={[{ label: "Any", value: "" }, ...locationStatuses]}
              />
              <ConnectOneDropdown
                handleChange={handleAdvancedSearchChange}
                label={"Type"}
                name={"type"}
                placeholder={"Type"}
                value={advancedFilterValues.type.value}
                options={[{ label: "Any", value: "" }, ...locationTypes]}
              />
              {!!locationSubTypes?.length && (
                <ConnectOneDropdown
                  handleChange={handleAdvancedSearchChange}
                  label={"Subtype"}
                  name={"subType"}
                  placeholder={"Subtype"}
                  value={advancedFilterValues.subType.value}
                  options={[{ label: "Any", value: "" }, ...locationSubTypes]}
                />
              )}
            </div>

            <h2>Rates Data</h2>
            <div className="rate-inputs-container">
              {chargeTypes.map((chargeType) => (
                <div className="rate-inputs">
                  <ConnectOneInput
                    handleChange={handleAdvancedSearchChange}
                    label={`${chargeType.label}`}
                    name={`${chargeType.value}.minAmount`}
                    placeholder={"Minimum Amount"}
                    value={
                      advancedFilterValues[chargeType.value].value.minAmount
                    }
                    type={"number"}
                    min="0"
                    readOnly={
                      advancedFilterValues[chargeType.value].value.isNull
                    }
                  />
                  <ConnectOneInput
                    handleChange={handleAdvancedSearchChange}
                    label={""}
                    name={`${chargeType.value}.maxAmount`}
                    placeholder={"Maximum Amount"}
                    value={
                      advancedFilterValues[chargeType.value].value.maxAmount
                    }
                    type={"number"}
                    min="0"
                    readOnly={
                      advancedFilterValues[chargeType.value].value.isNull
                    }
                  />
                  <ConnectOneCheckbox
                    handleChange={handleAdvancedSearchChange}
                    label={`${chargeType.label} Null?`}
                    name={`${chargeType.value}.isNull`}
                    value={advancedFilterValues[chargeType.value].value.isNull}
                    labelPosition="end"
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="advanced-search-submit">
            <ConnectOneButton
              label={"Search"}
              handleClick={getAdvancedSearchTableData}
              disabled={!submitButtonEnabled}
              isLoading={isLoading}
              toolTipMessage={
                !submitButtonEnabled ? "Agency is required to search" : ""
              }
            />
          </div>
        </ConnectOneModal>
      )}

      <ConnectOneTable
        data={dataToDisplay}
        headers={headers}
        caption="Agency Rates"
        sortGridData={handleSorting}
        sortAscending={sortAscending}
        sortedValue={sortedValue}
      />
    </div>
  );
};
