import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  ConnectOneInput,
  ConnectOneDropdown,
  ConnectOneButton,
  ExclusiveDateList,
} from "..";
import {
  DeleteIcon,
  RatesHeaders,
  daysOfWeek,
  daysOfWeekDropdownOptions,
} from "../../assets";
import { getTwentyFourHourTime } from "../../utils";

import "./RatesManagement.scss";

export const RatesForm = ({
  schedule,
  scheduleIndex,
  handleEditToggle,
  handleScheduleOrRateChange,
  handleScheduleChangeCancel,
  handleInsertScheduleOrRate,
  handleRemoveScheduleOrRate,
  handleRemoveExclusiveDate,
  handleScheduleSave,
}: {
  schedule: any;
  scheduleIndex: number;
  handleEditToggle: any;
  handleScheduleOrRateChange: any;
  handleScheduleChangeCancel: any;
  handleInsertScheduleOrRate: any;
  handleRemoveScheduleOrRate: any;
  handleRemoveExclusiveDate: any;
  handleScheduleSave: any;
}) => {
  const [showDatePicker, setShowDatePicker] = useState(
    schedule?.exclusiveDates && schedule?.exclusiveDates?.length === 0
  );

  useEffect(
    () => {
      // If user removes all exclusive dates, then show the date picker
      if (schedule?.exclusiveDates?.length === 0) setShowDatePicker(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [schedule?.exclusiveDates]
  );

  const classTypes = useSelector(
    (state: any) => state.classTypes.classTypesData
  );

  // Check if schedule has exclusive dates
  const isScheduleExclusiveDates = schedule?.exclusiveDates;

  // Check if all required charge rate values are defined
  const areAllChargeRatesDefined = !schedule?.charges?.some((charge: any) => {
    return !charge?.passengersCount || !charge?.avi || !charge?.plateRead;
  });

  return (
    <div
      className="schedule-form-container"
      key={`schedule-form-${scheduleIndex}`}
      data-testid={`schedule-form-${scheduleIndex}`}>
      <div className="schedule-inputs" key={`schedule-inputs-${scheduleIndex}`}>
        <ConnectOneDropdown
          handleChange={(e: any) =>
            handleScheduleOrRateChange({
              e,
              objectType: "schedule",
              index: scheduleIndex,
            })
          }
          label="Class/Axles"
          options={classTypes}
          name="vehicleClass"
          value={schedule?.vehicleClass || "Class1"}
          required={true}
        />
        {/* If no exclusive dates, then user needs to select a start and end dayOfWeek */}
        {!isScheduleExclusiveDates && (
          <>
            <ConnectOneDropdown
              handleChange={(e: any) =>
                handleScheduleOrRateChange({
                  e,
                  objectType: "schedule",
                  index: scheduleIndex,
                  type: "dayOfWeek",
                })
              }
              label="Starting Day of Week"
              name="dayOfWeekStart"
              value={daysOfWeek[schedule?.dayOfWeekStart - 1] || "Sunday"}
              options={daysOfWeekDropdownOptions}
              required={true}
            />
            <ConnectOneDropdown
              handleChange={(e: any) =>
                handleScheduleOrRateChange({
                  e,
                  objectType: "schedule",
                  index: scheduleIndex,
                  type: "dayOfWeek",
                })
              }
              label="Ending Day of Week"
              name="dayOfWeekEnd"
              value={daysOfWeek[schedule?.dayOfWeekEnd - 1] || "Saturday"}
              options={daysOfWeekDropdownOptions}
              required={true}
            />
          </>
        )}
        <ConnectOneInput
          handleChange={(e: any) =>
            handleScheduleOrRateChange({
              e,
              objectType: "schedule",
              index: scheduleIndex,
              type: "time",
            })
          }
          label="Start Time"
          type="time"
          name="timeOfDayStart"
          value={getTwentyFourHourTime(schedule.timeOfDayStart)}
          required={true}
        />
        <ConnectOneInput
          handleChange={(e: any) =>
            handleScheduleOrRateChange({
              e,
              objectType: "schedule",
              index: scheduleIndex,
              type: "time",
            })
          }
          label="End Time"
          type="time"
          name="timeOfDayEnd"
          value={getTwentyFourHourTime(schedule?.timeOfDayEnd)}
          required={true}
        />
      </div>

      {/* If exclusive dates, then user needs to select a date using a date picker  */}
      {isScheduleExclusiveDates && (
        <div className="exclusive-dates-form">
          <ExclusiveDateList
            exclusiveDates={schedule?.exclusiveDates}
            scheduleIndex={scheduleIndex}
            handleRemoveExclusiveDate={handleRemoveExclusiveDate}
          />

          {isScheduleExclusiveDates && !showDatePicker && (
            <span className="add-date-button">
              <ConnectOneButton
                label="+ Add new date"
                handleClick={() => setShowDatePicker(!showDatePicker)}
              />
            </span>
          )}
          {isScheduleExclusiveDates && showDatePicker && (
            <ConnectOneInput
              handleChange={(e: any) => {
                handleScheduleOrRateChange({
                  e,
                  objectType: "schedule",
                  index: scheduleIndex,
                  type: "exclusiveDates",
                });
                setShowDatePicker(false);
              }}
              label={schedule?.exclusiveDates?.length === 0 ? "Add date" : ""}
              type="date"
              name="exclusiveDates"
              value={""}
              required={schedule?.exclusiveDates?.length === 0}
              exclusiveDate={true}
            />
          )}
        </div>
      )}

      {!!schedule?.charges?.length && (
        <div className="charges-inputs-container">
          {schedule.charges.map((charge: any, index: number) => {
            // User should only be able to select a passenger count from 1-5 with no duplicates selected
            const passengersOptions = [
              { label: "1", value: "1" },
              { label: "2", value: "2" },
              { label: "3", value: "3" },
              { label: "4", value: "4" },
              { label: "5", value: "5" },
            ];

            const usedPassengerCounts = schedule.charges.map(
              (charge: any) => `${charge.passengersCount}`
            );

            const availablePassengerOptions = passengersOptions.filter(
              (option) => !usedPassengerCounts.includes(`${option.value}`)
            );

            if (charge.passengersCount) {
              availablePassengerOptions.push({
                label: `${charge.passengersCount}`,
                value: `${charge.passengersCount}`,
              });

              availablePassengerOptions.sort(
                (a: any, b: any) => a.value - b.value
              );
            }

            return (
              <div
                className="charge-form-container"
                key={`charge-form-${index}`}>
                <div key={`charges-form-${index}`} className="charges-inputs">
                  <ConnectOneDropdown
                    handleChange={(e: any) =>
                      handleScheduleOrRateChange({
                        e,
                        objectType: "charge",
                        index: index,
                        parentIndex: scheduleIndex,
                      })
                    }
                    options={availablePassengerOptions}
                    name="passengersCount"
                    label="Passengers"
                    value={charge.passengersCount}
                    required={true}
                  />
                  {RatesHeaders.map((header: any, chargeIndex: number) => {
                    if (header.value === "passengersCount") return null;

                    return (
                      <div key={`charge-input-${chargeIndex}`}>
                        <ConnectOneInput
                          handleChange={(e: any) =>
                            handleScheduleOrRateChange({
                              e,
                              objectType: "charge",
                              index: index,
                              parentIndex: scheduleIndex,
                            })
                          }
                          label={header.name}
                          type="number"
                          name={header.value}
                          value={charge[header.value]}
                          required={header?.required || false}
                          min="0"
                        />
                      </div>
                    );
                  })}
                </div>
                {/* Only show remove charge if more than one available */}
                {schedule.charges.length > 1 && (
                  <span
                    data-testid="remove-prop-button"
                    onClick={() =>
                      handleRemoveScheduleOrRate({
                        objectType: "charge",
                        index,
                        parentIndex: scheduleIndex,
                      })
                    }>
                    <DeleteIcon className="delete-icon" />
                  </span>
                )}
              </div>
            );
          })}
        </div>
      )}

      <div className="schedule-button-container">
        <div className="close-button">
          <ConnectOneButton
            label="Add New Fee"
            handleClick={() =>
              handleInsertScheduleOrRate({
                objectType: "charge",
                index: scheduleIndex,
              })
            }
          />
        </div>
        <div className="close-button">
          <ConnectOneButton
            label="Cancel"
            handleClick={handleScheduleChangeCancel}
            color="red"
          />
        </div>
        <div className="save-button">
          <ConnectOneButton
            label="Save Rate"
            handleClick={() => {
              handleScheduleSave();
              handleEditToggle();
            }}
            isLoading={false}
            disabled={
              !isScheduleExclusiveDates
                ? !areAllChargeRatesDefined
                : !areAllChargeRatesDefined ||
                  schedule?.exclusiveDates?.length === 0
            }
          />
        </div>
      </div>
    </div>
  );
};
