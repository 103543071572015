import {
  ConnectOneModal,
  ConnectOneButton,
  ConnectOneDropdown,
} from "../../../components";
import { Autocomplete } from "@aws-amplify/ui-react";

import "./Agencies.scss";

export const AgenciesAdvancedSearch = ({
  title = "Advanced Search",
  searchData,
  isLoading,
  handleChange,
  handleSubmit,
  handleClose,
  onAutoCompleteSelect,
  autoCompleteLabel,
  setAutoCompleteValue,
  autoCompleteValue,
  autoCompleteOptions,
}: {
  title?: string;
  searchData?: any;
  isLoading?: boolean;
  handleChange?: any;
  handleAutoCompleteChange?: any;
  handleSubmit?: any;
  handleClose?: any;
  onAutoCompleteSelect?: any;
  autoCompleteLabel?: string;
  setAutoCompleteValue?: any;
  autoCompleteValue?: string;
  autoCompleteOptions?: any;
}) => {
  const onAutoCompleteClear = () => {
    setAutoCompleteValue("");
  };
  const handleFormSubmit = () => {
    handleSubmit(autoCompleteValue);
  };
  return (
    <ConnectOneModal title={title} handleClose={handleClose}>
      <div className="advanced-search-container">
        <h2>Agency Data</h2>
        <div className="inputs-container">
          {/* Agency Name*/}
          <div className="autocomplete-container">
            <div className="label">{autoCompleteLabel}</div>
            <div className="position-relative">
              <span className="autocomplete-label">{autoCompleteLabel}</span>
              <Autocomplete
                label="Default Autocomplete"
                options={autoCompleteOptions}
                value={autoCompleteValue}
                placeholder={"Select an Agency"}
                onChange={(event: any) => {
                  setAutoCompleteValue(event.target.value);
                  handleChange(event);
                }}
                onSelect={(option: any) => {
                  onAutoCompleteSelect(option);
                  setAutoCompleteValue(option.label);
                }}
                onClear={onAutoCompleteClear}
                className="autocomplete"
              />
            </div>
          </div>
          {/* State */}
          <ConnectOneDropdown
            handleChange={handleChange}
            label={"State"}
            name={"state"}
            options={[{ label: "Any", value: "" }, ...searchData.state.options]}
            value={searchData.state.value}
          />
          {/* Status */}
          <ConnectOneDropdown
            handleChange={handleChange}
            label={"Status"}
            name={"status"}
            placeholder={"Status"}
            value={searchData.status.value}
            options={[
              { label: "Any", value: "" },
              ...searchData.status.options,
            ]}
          />
          {/* Services */}
          <ConnectOneDropdown
            handleChange={handleChange}
            label={"Services"}
            name={"services"}
            placeholder={"Services"}
            value={searchData.services.value}
            options={[
              { label: "Any", value: "" },
              ...searchData.services.options,
            ]}
          />
        </div>

        <div className="advanced-search-submit">
          <ConnectOneButton
            label={"Search"}
            handleClick={handleFormSubmit}
            disabled={false}
            isLoading={isLoading}
          />
        </div>
      </div>
    </ConnectOneModal>
  );
};
