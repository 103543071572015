import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "@aws-amplify/ui-react";

import { BulkUpload } from "./BulkUpload";
import { AppDispatch } from "../../../store";
import { LocationsEditForm } from "./LocationsEditForm";
import { findAllEntryPoints, shapeCSVData } from "../../../utils";
import { useAdmin, useGeofence, useIsMobile } from "../../../hooks";
import { LocationsAdvancedSearch } from "./LocationsAdvancedSearch";
import {
  GeofenceHeaders,
  LocationHeaders,
  locationsFilterState,
} from "../../../assets";
import {
  handleGeofenceSort,
  insertAgencyNames,
  handleGeofenceFilter,
  insertLocationStatusNames,
} from "../../../redux";
import {
  ConnectOneClickableTable,
  ConnectOneLoader,
} from "../../../components";

import "./Locations.scss";

export const AdminControlLocations = ({ user }: { user?: any }) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [userSearched, setUserSearch] = useState(false);
  const [exportData, setExportData] = useState({
    cellHeaders: null,
    cellData: null,
  });
  const [templateData, setTemplateData] = useState({
    cellHeaders: null,
    cellData: null,
  });

  const {
    initialLoad,
    geofencesData,
    rawGeofenceData,
    isLoading: geofencesLoading,
  } = useSelector((state: any) => state.geofences);
  const { agenciesData, isLoading: agenciesLoading } = useSelector(
    (state: any) => state.agencies
  );
  const { locationStatusesData, isLoading: locationStatusesLoading } =
    useSelector((state: any) => state.locationStatuses);
  const locationHierarchy = useSelector(
    (state: any) => state.locationHierarchy.locationHierarchyData
  );

  useEffect(() => {
    if (agenciesData.length) {
      locationsFilterState.agencyId.options = agenciesData;
    }
    if (locationStatusesData.length) {
      locationsFilterState.status.options = locationStatusesData;
    }
    if (locationHierarchy?.get) {
      const locationTypes: { label: string; value: string }[] = [];

      locationHierarchy.forEach((locationType: any) => {
        locationTypes.push({
          label: locationType.locationType,
          value: locationType.locationType,
        });
      });

      locationsFilterState.locationType.options = locationTypes;
    }

    // Any dropdown type should be given an Any option
    Object.entries(locationsFilterState).forEach(
      ([key, value]: [key: string, value: any]) => {
        if (value.type === "dropdown") {
          // Do not add a second Any option
          if (value?.options[0] && value.options[0].value !== "") {
            value.options = [{ label: "Any", value: "" }, ...value.options];
          }
        }
      }
    );
  }, [agenciesData, locationHierarchy, locationStatusesData]);

  const {
    isSearching,
    isLoading,
    searchData,
    displayingFilteredData,
    sortedValue,
    sortAscending,
    handleFilterInputChange,
    setIsSearching,
    handleResetSearch,
    setSortedValue,
    setSortAscending,
  } = useAdmin({
    filterState: locationsFilterState,
    user,
  });

  const {
    modalData,
    displayGeofenceModal,
    handleRemoveScheduleOrRate,
    handleGeofenceSelection,
    handleGeofenceModalClose,
    handleGeofenceSave,
    handleGeofencePropertyChange,
    handleScheduleOrRateChange,
    handleInsertScheduleOrRate,
    handleScheduleSave,
    handleUndoScheduleChanges,
    handleRemoveExclusiveDate,
  } = useGeofence({
    rawGeofenceData: rawGeofenceData,
    isOnAdminPage: true,
  });

  useEffect(() => {
    if (geofencesData?.features) {
      const { cellHeaders, cellData } = shapeCSVData(
        LocationHeaders,
        geofencesData.features
      );

      setExportData({ cellHeaders, cellData });
    }
  }, [geofencesData.features]);

  useEffect(() => {
    if (geofencesData?.features) {
      const { cellHeaders, cellData } = shapeCSVData(
        GeofenceHeaders,
        geofencesData.features
      );

      setTemplateData({ cellHeaders, cellData });
    }
  }, [geofencesData.features]);

  useEffect(() => {
    if (!agenciesLoading && !geofencesLoading) {
      dispatch(insertAgencyNames({ agencies: agenciesData }));
    }
  }, [agenciesData, agenciesLoading, dispatch, geofencesLoading]);

  useEffect(() => {
    if (!locationStatusesLoading && !geofencesLoading) {
      dispatch(
        insertLocationStatusNames({ locationStatuses: locationStatusesData })
      );
    }
  }, [
    dispatch,
    geofencesLoading,
    locationStatusesData,
    locationStatusesLoading,
  ]);

  const handleViewOnMap = (locationId: string) => {
    navigate({
      pathname: "/",
      search: `?locationId=${locationId}`,
    });
  };

  const handleSortGrid = (header: string) => {
    if (sortedValue === header && !sortAscending) {
      setSortAscending(true);
      dispatch(
        handleGeofenceSort({ sortedValue: header, sortAscending: true })
      );
    } else {
      setSortedValue(header);
      setSortAscending(false);
      dispatch(
        handleGeofenceSort({ sortedValue: header, sortAscending: false })
      );
    }
  };

  const handleFilterLocations = ({
    resetFilters = false,
  }: {
    resetFilters?: boolean;
  }) => {
    const filterParams: any = {};

    if (resetFilters) {
      setSelectedOption(null);
      setAutoCompleteValue("");
      setUserSearch(false);
    }

    if (!resetFilters) {
      Object.entries(searchData).forEach(
        ([key, value]: [key: string, value: any]) => {
          if (value.value) {
            filterParams[key] = value.value;
          }
        }
      );
    }

    dispatch(handleGeofenceFilter(filterParams));
    setIsSearching(false);
  };

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  // AutoComplete Logic
  const [autoCompleteValue, setAutoCompleteValue] = useState("");
  const [selectedOption, setSelectedOption] = useState<any>(null);

  const autoCompleteOptions = geofencesData.features
    .map((item: any) => ({
      _id: item._id || "",
      label: item.name || "",
    }))
    .sort((a: { label: string }, b: { label: any }) =>
      a.label.localeCompare(b.label)
    );

  const onAutoCompleteSelect = (option: any) => {
    setAutoCompleteValue(option.label);
    setSelectedOption(option);
  };

  const handleSearch = () => {
    setUserSearch(true);
    if (selectedOption) {
      const selectedRecord = geofencesData.features.find(
        (record: any) => record._id === selectedOption._id
      );
      if (selectedRecord?._id) {
        dispatch(handleGeofenceFilter({ locationId: selectedRecord._id }));
      }
    }
  };

  return (
    <div
      className={`${
        isMobile ? "mobile-locations-admin-container" : null
      } locations-admin-container`}>
      {initialLoad && <ConnectOneLoader />}

      <div>
        <ConnectOneClickableTable
          data={geofencesData.features}
          label={"Locations"}
          headers={LocationHeaders}
          exportData={exportData}
          sortGridData={handleSortGrid}
          resetButtonLabel={"Clear Search"}
          handleRowSelection={(row: any) =>
            handleGeofenceSelection({ features: [row] })
          }
          handleSearch={handleSearch}
          handleReset={
            displayingFilteredData || userSearched
              ? () => {
                  handleResetSearch();
                  handleFilterLocations({ resetFilters: true });
                }
              : undefined
          }
          sortedValue={sortedValue}
          sortAscending={sortAscending}
          autoCompleteValue={autoCompleteValue}
          setAutoCompleteValue={setAutoCompleteValue}
          autoCompleteOptions={autoCompleteOptions}
          onAutoCompleteSelect={onAutoCompleteSelect}
          autoCompleteLabel={"Location"}
          autCompletePlaceholder={"Select a Location"}
          description="List of Toll Plazas associated with the Agency along with their Status and rates schedule."
          advancedSearchLabel={"Advanced"}
          handleAdvancedSearch={() => setIsSearching(true)}
          searchButtonDisabled={!autoCompleteValue}
        />
      </div>

      {isSearching && (
        <LocationsAdvancedSearch
          searchData={searchData}
          isLoading={isLoading}
          handleChange={handleFilterInputChange}
          handleClose={() => setIsSearching(false)}
          handleSubmit={handleFilterLocations}
        />
      )}

      <div className="button-container-locations">
        <div className="add-button">
          <Button className="locations-button" onClick={handleShow}>
            Upload Bulk Geofences
          </Button>
        </div>
      </div>
      <BulkUpload
        exportHeaders={templateData.cellHeaders}
        show={show}
        setShow={setShow}
      />

      {displayGeofenceModal && (
        <LocationsEditForm
          geofence={
            modalData?.properties?.locationSubType === "DISTANCEEXIT"
              ? {
                  ...modalData,
                  entryPoints: findAllEntryPoints({
                    rawGeofenceData,
                    selectedGeofence: modalData,
                  }),
                }
              : modalData
          }
          isLoading={geofencesLoading}
          handleChange={handleGeofencePropertyChange}
          handleGoBack={handleGeofenceModalClose}
          handleSubmit={async () => {
            await handleGeofenceSave({});
            handleFilterLocations({});
          }}
          handleViewOnMap={handleViewOnMap}
          handleInsertScheduleOrRate={handleInsertScheduleOrRate}
          handleScheduleOrRateChange={handleScheduleOrRateChange}
          handleRemoveScheduleOrRate={handleRemoveScheduleOrRate}
          handleScheduleSave={handleScheduleSave}
          handleRemoveExclusiveDate={handleRemoveExclusiveDate}
          handleUndoScheduleChanges={handleUndoScheduleChanges}
          extraMargin={true}
        />
      )}
    </div>
  );
};
